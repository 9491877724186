import { DateTimeFormatter, ZonedDateTime, ZoneId } from '@js-joda/core'

const DATE_FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM-dd')

const DATETIME_FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm')

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export function formatAsDatetime(
    dateTime: string,
    localTime: boolean = true,
    formatter: DateTimeFormatter = DATETIME_FORMATTER
): string {
    try {
        let zdt = ZonedDateTime.parse(dateTime)
        if (localTime) {
            zdt = zdt.withZoneSameInstant(ZoneId.systemDefault())
        }
        return zdt.format(formatter)
    } catch (e: any) {
        console.error(e)
        return 'N/A'
    }
}

export function formatAsDate(dateTime: string): string {
    return ZonedDateTime.parse(dateTime).format(DATE_FORMATTER)
}

export function formatAsDateTimeWithMonthAsWord(ISO8601Timestamp: string): string {
    const commentDate = new Date(ISO8601Timestamp)
    const day = commentDate.getUTCDate() // Get the day of the month
    const dayWithSuffix = day + _getOrdinalSuffix(day) // Append the correct suffix
    const month = monthNames[commentDate.getUTCMonth()] // Get the month name
    const hours = ('0' + commentDate.getUTCHours()).slice(-2)
    const minutes = ('0' + commentDate.getUTCMinutes()).slice(-2)
    const seconds = ('0' + commentDate.getUTCSeconds()).slice(-2)
    const ms = ('0' + commentDate.getUTCMilliseconds()).slice(-3)
    return `${month} ${dayWithSuffix}, ${hours}:${minutes}:${seconds}.${ms}`
}

export function formatAsHHMMSSmmm(ISO8601Timestamp: string): string {
    const commentDate = new Date(ISO8601Timestamp)

    const hours = ('0' + commentDate.getUTCHours()).slice(-2)
    const minutes = ('0' + commentDate.getUTCMinutes()).slice(-2)
    const seconds = ('0' + commentDate.getUTCSeconds()).slice(-2)
    const milliseconds = ('00' + commentDate.getUTCMilliseconds()).slice(-3)

    return `${hours}:${minutes}:${seconds}.${milliseconds}`
}

// Function to get the ordinal suffix for a day
function _getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th' // Handles 11th, 12th, 13th
    switch (day % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}

import { FrameEntry, SignalEntry } from '../../../api/CloudApi/types'

export enum AnalyticsPanelType {
    SIGNAL_TIME_SERIES = 'SIGNAL_TIME_SERIES',
    MAP_WITH_ROUTE = 'MAP_WITH_ROUTE',
    TIME_DISTRIBUTION = 'TIME_DISTRIBUTION',
}

export const ANALYTICS_CHART_TYPE_KEY = 'analytics-chart-type-key'

export type SharedExtremes = {
    xMax: number | undefined
    xMin: number | undefined
}

export type GraphSettings = {
    showYAxis: boolean
    showXAxis: boolean
    showAllAnnotations: boolean
    showOnlyMyAnnotations: boolean
    useSuggestedMinMax: boolean
}

export type SignalOption = {
    value: string
    label: string
}

export type PanelKey = {
    key: string
}

export type CompressedPanelKey = {
    k: string
}

export type TimeDistributionResult = {
    name: string
    annotations: {
        expectedCycleTime: number
    }
    values: Array<Array<number>>
}

// Dashboard
export type DeprecatedDashboard = {
    tsp: any
    tdp: any
    mp: any
}

export type Dashboard = {
    timeSeriesPanels: Array<TimeSeriesPanel>
    timeDistributionPanels: Array<TimeDistributionPanel>
    mapPanels: Array<MapPanel>
}

export type TimeDistributionPanel = {
    panelKey: PanelKey
    selectedFrame: FrameEntry | undefined
}

export type TimeSeriesPanel = {
    panelKey: PanelKey
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
}

export type MapPanel = {
    panelKey: PanelKey
    latitudeSignal: SignalEntry | undefined
    longitudeSignal: SignalEntry | undefined
}

// Stored Dashboard
export type StorableDashboard = {
    timeSeriesPanels: Array<StorableTimeSeriesPanel>
    timeDistributionPanels: Array<StorableTimeDistributionPanel>
    mapPanels: Array<StorableMapPanel>
}

export type StorableSignalEntry = {
    name: string
    frameName: string
    namespace: string
}

export type PlottableSignalEntry = {
    signalEntry: SignalEntry
    signalData: Array<Array<number>>
}

export type StorableFrameEntry = {
    name: string
    namespace: string
}

export type StorableTimeDistributionPanel = {
    panelKey: PanelKey
    selectedFrame: StorableFrameEntry | undefined
}

export type StorableTimeSeriesPanel = {
    panelKey: PanelKey
    selectedSignals: Array<StorableSignalEntry>
    hiddenSignals: Array<StorableSignalEntry>
}

export type StorableMapPanel = {
    panelKey: PanelKey
    latitudeSignal: StorableSignalEntry | undefined
    longitudeSignal: StorableSignalEntry | undefined
}

import {
    AuthenticatedUser,
    EditRecordingAnnotationRequest,
    Project,
    RecordingAnnotation,
    RecordingAnnotationComment,
    RecordingAnnotationCommentRequest,
    RecordingSession,
} from '../../../../api/CloudApi/types'
import { CrossIcon, DeleteIcon, EditIcon, MoreVerticalIcon, ProfileIcon, SaveIcon } from '../../../../assets/Icons'

import { Button, Dropdown, Form, Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { formatAsDateTimeWithMonthAsWord } from '../../../../utils/datetime'
import CloudApi from '../../../../api/CloudApi'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../utils/toast'
import AnnotationComment from './AnnotationComment'

interface AnnotationComponentProps {
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    currentRecordingSession: RecordingSession | undefined
    annotation: RecordingAnnotation | undefined
    refreshAnnotation: Function
    maxHeight: number
    commentMaxWidth?: number
    titleOffset?: number
    className: string
    onClose?: Function
    onDelete: Function
}

const SCROLLABLE_COMMENT_LIST_HTML_ID = 'scrollable-comment-list-html-id'

function isBlankOrEmptyNewLines(input: string): boolean {
    return /^\s*$/.test(input)
}

export default function AnnotationComponent(props: AnnotationComponentProps) {
    const [isSubmittingComment, setIsSubmittingComment] = useState<boolean>(false)
    const [isDeletingAnnotation, setIsDeletingAnnotation] = useState<boolean>(false)
    const [commentToSubmit, setCommentToSubmit] = useState<string>('')

    const [isSubmittingEditedTitle, setIsSubmittingEditedTitle] = useState<boolean>(false)
    const [editedTitle, setEditedTitle] = useState<string>()
    const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false)

    const [showAnnotationOptionsDropdown, setShowAnnotationOptionsDropdown] = useState(false)

    useEffect(() => {
        if (props.annotation !== undefined) {
            const scrollableCommentListDiv = document.getElementById(SCROLLABLE_COMMENT_LIST_HTML_ID)
            // This will force the comment list to scroll down to the latest comment
            if (scrollableCommentListDiv !== null) {
                scrollableCommentListDiv.scrollTop = scrollableCommentListDiv.scrollHeight
            }
            setEditedTitle(props.annotation.title)
        }
    }, [props.annotation])

    const deleteAnnotation = async (
        currentProject: Project | undefined,
        currentRecordingSession: RecordingSession | undefined,
        currentAnnotation: RecordingAnnotation
    ) => {
        setShowAnnotationOptionsDropdown(false)
        setIsDeletingAnnotation(true)
        try {
            await CloudApi.deleteRecordingAnnotation(currentProject!, currentRecordingSession!, currentAnnotation)

            await props.onDelete()
            toast.success(
                formattedToastMessage(
                    'Annotation deleted',
                    `Successfully deleted the annotation ${currentAnnotation.title} from ${currentRecordingSession?.displayName}`
                )
            )
        } catch (e: any) {
            console.error(e)
            toast.error(formattedToastMessage('Error', 'Failed to delete annotation, please try again later.'))
        }
        setIsDeletingAnnotation(false)
    }

    const annotationOptionsDropdown = (
        currentProject: Project | undefined,
        currentRecordingSession: RecordingSession | undefined,
        currentAnnotation: RecordingAnnotation
    ) => {
        return (
            <>
                <Dropdown style={{ marginLeft: -85 }} className="" show={showAnnotationOptionsDropdown}>
                    <Dropdown.Menu className="py-1 d-flex flex-column align-items-start remotive-dropdown-light border-0 shadow">
                        <Dropdown.Item
                            as={Button}
                            disabled={currentAnnotation.createdBy.uid !== props.currentUser?.uid}
                            className="m-0 py-1 px-2 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                deleteAnnotation(currentProject, currentRecordingSession, currentAnnotation)
                            }}
                        >
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <p className="me-3 m-0 remotive-font-sm">Delete</p>
                                <DeleteIcon sx={{ fontSize: 14 }} style={{ marginBottom: 1 }} />
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-1 px-2 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                setIsEditModeActive(true)
                            }}
                        >
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <p className="me-3 m-0 remotive-font-sm">Edit title</p>
                                <EditIcon sx={{ fontSize: 14 }} />
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    const submitComment = async (
        project: Project,
        recordingSession: RecordingSession,
        annotation: RecordingAnnotation
    ) => {
        setIsSubmittingComment(true)
        const request = { text: commentToSubmit } as RecordingAnnotationCommentRequest
        const response = await CloudApi.createRecordingAnnotationComment(project, recordingSession, annotation, request)
        await props.refreshAnnotation()
        setCommentToSubmit('')
        setIsSubmittingComment(false)
    }

    const cancelEditTitle = () => {
        setIsEditModeActive(false)
    }

    const submitEditedAnnotationTitle = async (
        project: Project,
        recordingSession: RecordingSession,
        annotation: RecordingAnnotation,
        newTitle: string
    ) => {
        setIsSubmittingEditedTitle(true)
        try {
            const request = { title: newTitle } as EditRecordingAnnotationRequest
            const response = await CloudApi.editRecordingAnnotation(project, recordingSession, annotation, request)
            if (response.status >= 200 && response.status < 300) {
                await props.refreshAnnotation()
                setIsEditModeActive(false)
            }
        } catch (e: any) {
            console.error(e)
            toast.error(formattedToastMessage('Error', 'Failed to edit annotation title, please try again later.'))
        }
        setIsSubmittingEditedTitle(false)
    }

    const annotationCommentBox = (
        annotation: RecordingAnnotation | undefined,
        currentUser: AuthenticatedUser | undefined
    ) => {
        if (annotation === undefined) {
            return <></>
        }

        return (
            <div
                id="visualize-comment-div"
                className={`rounded-4 ${props.className}`}
                style={{ height: props.maxHeight }}
            >
                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-row flex-truncate w-100">
                            {props.onClose !== undefined && (
                                <div className="me-1">
                                    <button
                                        title="Close comments section"
                                        onClick={() => props.onClose!()}
                                        className="btn remotive-btn-no-bg border-0 p-0"
                                    >
                                        {<CrossIcon sx={{ fontSize: 22 }} />}
                                    </button>
                                </div>
                            )}
                            <div className="ps-1 pt-1 pb-0 flex-truncate w-100">
                                {isEditModeActive ? (
                                    <div className="d-flex flex-column">
                                        <Form.Control
                                            className="remotive-font-sm text-wrap"
                                            placeholder="Add your comment here..."
                                            as={'textarea'}
                                            disabled={isSubmittingEditedTitle}
                                            value={editedTitle}
                                            onChange={(it: any) => {
                                                setEditedTitle(it.target.value)
                                            }}
                                            style={{ height: 18, resize: 'none' }}
                                        />
                                        <div className="d-flex justify-content-end">
                                            <button
                                                disabled={isSubmittingEditedTitle}
                                                onClick={() => cancelEditTitle()}
                                                className="btn remotive-btn-primary remotive-btn-sm mb-0"
                                            >
                                                <span className="d-none d-md-inline-block">Cancel</span>
                                                <span className="d-md-none">
                                                    <CrossIcon sx={{ fontSize: 15 }} />
                                                </span>
                                            </button>
                                            <button
                                                disabled={isSubmittingEditedTitle || editedTitle === ''}
                                                onClick={() => {
                                                    if (editedTitle !== undefined && editedTitle !== '') {
                                                        if (
                                                            props.currentProject !== undefined &&
                                                            props.currentRecordingSession !== undefined &&
                                                            props.annotation !== undefined
                                                        ) {
                                                            submitEditedAnnotationTitle(
                                                                props.currentProject,
                                                                props.currentRecordingSession,
                                                                props.annotation,
                                                                editedTitle
                                                            )
                                                        } else
                                                            toast.error(
                                                                formattedToastMessage(
                                                                    'Error',
                                                                    'Could not submit the annotation title due to a parameter error. Please refresh the page and try again.'
                                                                )
                                                            )
                                                    }
                                                }}
                                                className="btn remotive-btn-success remotive-btn-sm mb-0 d-flex align-items-center justify-content-center"
                                            >
                                                {isSubmittingEditedTitle ? (
                                                    <Spinner size="sm" style={{ height: 10, width: 10 }} />
                                                ) : (
                                                    <>
                                                        <span className="d-none d-md-inline-block">Save</span>
                                                        <span className="d-md-none">
                                                            <SaveIcon sx={{ fontSize: 15 }} />
                                                        </span>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <p className="mb-0 remotive-font-md text-truncate">{annotation.title}</p>
                                    </>
                                )}

                                {!isEditModeActive && (
                                    <p className="mb-0 remotive-font-xs text-secondary text-truncate">{`Created by ${annotation.createdBy.displayName}`}</p>
                                )}
                            </div>
                        </div>
                        <div>
                            {annotation &&
                                showAnnotationOptionsDropdown &&
                                annotationOptionsDropdown(
                                    props.currentProject,
                                    props.currentRecordingSession,
                                    annotation
                                )}
                            {isDeletingAnnotation ? (
                                <Spinner size="sm" className="border-2" style={{ height: 13, width: 13 }} />
                            ) : (
                                annotation.createdBy.uid === props.currentUser?.uid && (
                                    <button
                                        onClick={() => setShowAnnotationOptionsDropdown(!showAnnotationOptionsDropdown)}
                                        onBlur={() => setShowAnnotationOptionsDropdown(false)}
                                        className="btn remotive-btn-no-bg p-0 m-0"
                                    >
                                        <MoreVerticalIcon sx={{ fontSize: 20 }} />
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                    <div
                        id={SCROLLABLE_COMMENT_LIST_HTML_ID}
                        className="overflow-y-scroll px-1"
                        style={{ height: props.maxHeight - 140 }}
                    >
                        {annotation.comments.map((it) => (
                            <AnnotationComment
                                maxWidth={props.commentMaxWidth}
                                key={`${annotation.title}${annotation.timestamp}-${it.id}`}
                                refreshAnnotation={props.refreshAnnotation}
                                currentUser={currentUser}
                                currentAnnotation={annotation}
                                currentProject={props.currentProject}
                                currentRecordingSession={props.currentRecordingSession}
                                comment={it}
                            />
                        ))}
                    </div>
                    <div className="px-2 pt-1 pb-0">
                        <div className="d-flex flex-column">
                            <Form.Control
                                className="remotive-font-sm text-wrap"
                                placeholder="Add your comment here..."
                                as={'textarea'}
                                disabled={isSubmittingComment}
                                value={commentToSubmit}
                                onKeyDownCapture={(it) => {
                                    if (!isBlankOrEmptyNewLines(commentToSubmit) && it.key === 'Enter') {
                                        if (
                                            props.currentProject !== undefined &&
                                            props.currentRecordingSession !== undefined &&
                                            props.annotation !== undefined
                                        ) {
                                            submitComment(
                                                props.currentProject,
                                                props.currentRecordingSession,
                                                props.annotation
                                            )
                                        } else
                                            toast.error(
                                                formattedToastMessage(
                                                    'Error',
                                                    'Could not submit the comment due to a parameter error. Please refresh the page and try again.'
                                                )
                                            )
                                    }
                                }}
                                onChange={(it: any) => {
                                    setCommentToSubmit(it.target.value)
                                }}
                                style={{ height: 70, resize: 'none' }}
                            />
                            <div className="d-flex justify-content-end align-items-end">
                                <Button
                                    disabled={isBlankOrEmptyNewLines(commentToSubmit)}
                                    onClick={() => {
                                        if (
                                            props.currentProject !== undefined &&
                                            props.currentRecordingSession !== undefined &&
                                            props.annotation !== undefined
                                        ) {
                                            submitComment(
                                                props.currentProject,
                                                props.currentRecordingSession,
                                                props.annotation
                                            )
                                        } else
                                            toast.error(
                                                formattedToastMessage(
                                                    'Error',
                                                    'Could not submit the comment due to a parameter error. Please refresh the page and try again.'
                                                )
                                            )
                                    }}
                                    style={{ minWidth: 67 }}
                                    className="remotive-btn-sm remotive-btn-primary me-0 d-flex align-items-center justify-content-center"
                                >
                                    {isSubmittingComment ? (
                                        <Spinner
                                            size="sm"
                                            className="border-2 my-1"
                                            style={{ height: 10, width: 10 }}
                                        />
                                    ) : (
                                        <p className="mb-0 remotive-font-sm">Submit</p>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return annotationCommentBox(props.annotation, props.currentUser)
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import {
    AccessToken,
    AgreementsAnswersRequest,
    AuthenticatedUser,
    BillableUnit,
    BrokerConfigurationFolder,
    BrokerContainer,
    CreateBuRequest,
    CreateProjectRequest,
    CreateProjectServiceAccountDTO,
    CreateRecordingAnnotationRequest,
    CreateServiceAccountTokenDTO,
    CreateUserInfoWithRoles,
    EditProjectServiceAccountDTO,
    EditRecordingAnnotationRequest,
    FeatureEnabledStateRequest,
    FileObjectsContainer,
    FrameEntry,
    MediaFile,
    Organisation,
    PersonalAccessTokenDTO,
    ProcessingUploadFile,
    Project,
    ProjectServiceAccount,
    ProjectServiceAccountDTO,
    RecordingAnnotation,
    RecordingAnnotationComment,
    RecordingAnnotationCommentRequest,
    RecordingFile,
    RecordingSession,
    ServiceAccountKeyCreatedDTO,
    ServiceAccountTokenDTO,
    SignalDatabase,
    SignalIdentifier,
    SignalList,
    SignalNameList,
    SourceRecordingFile,
    StorageUploadResponse,
    TokenTtl,
    TwoDimensionalQueryResult,
    UserBillableUnitInfo,
    UserInfoWithRoles,
    UserLicense,
    VisualizeProcessingTracker,
} from './types'
import { FileWithPath } from 'react-dropzone'

class MonolithApi {
    getBackendURL = () => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL || `${window.location.protocol}//${window.location.host}`
        console.log(`BackendURL=${backendUrl}`)
        return backendUrl
    }

    /* API clients */

    authHeader = () => `Bearer  ${this.getCookie('auth')}`

    hasAuthHeaderCookie = () => this.getCookie('auth') !== undefined

    constructor() {
        this.remotiveApiClient.interceptors.response.use(
            function (response) {
                return response
            },
            function (error: any) {
                console.error(
                    `${error.response?.data} ${error.response?.config?.method} ${error.response?.config?.url} ${error.response?.status} (${error.response?.statusText})`
                )
                return Promise.reject(error)
            }
        )

        const getAuthHeader = this.authHeader

        this.remotiveApiClient.interceptors.request.use(function (req) {
            req.headers['Authorization'] = getAuthHeader()
            return req
        })
    }

    validateUnauthenicated = (status: number) => {
        if (status === 401 && window.location.pathname !== '/' && window.location.pathname !== '/noaccount') {
            console.log(window.location.pathname)
            // TODO - this toast is not seen due to the page refresh afterwards
            // If we could use a route instead it might work but this is not a react component
            toast.error('Your session has expired', {
                toastId: 'api.unauthenticated',
            })
            window.localStorage.setItem('redirectUrl', window.location.toString())
            window.location.replace('/')
        }
    }

    validateForbidden = (status: number) => {
        if (status === 403) {
            // TODO - consider if we should route the user to organisation home here
            toast.error('You are not allowed to access this resource', {
                toastId: 'api.unauthorized',
            })
        }
    }

    validateFeatureNotActivated = (status: number) => {
        if (status === 402) {
            toast.error('This feature has not been enabled yet, please request access and try again.', {
                toastId: 'api.feature.forbiddem',
            })
        }
    }

    remotiveApiClient: AxiosInstance = axios.create({
        baseURL: this.getBackendURL(),
        withCredentials: true,
        validateStatus: (status) => {
            this.validateUnauthenicated(status)
            this.validateForbidden(status)
            this.validateFeatureNotActivated(status)
            return status >= 200 && status < 300 // default
        },
    })

    externalApiClient: AxiosInstance = axios.create({
        validateStatus: function (status) {
            return status >= 200 && status < 300 // default
        },
    })

    getCookie = (name: string) => {
        return document.cookie
            .split(';')
            .map((row) => row.trim())
            .find((row) => row.startsWith(`${name}=`))
            ?.split('=')[1]
    }

    removeCookie = (name: string, path: string | undefined = undefined, domain: string | undefined = undefined) => {
        if (this.getCookie(name)) {
            document.cookie =
                name +
                '=' +
                (path ? ';path=' + path : ';path=/') +
                (domain ? ';domain=' + domain : '') +
                ';expires=Thu, 01 Jan 1970 00:00:01 GMT' // This will force the cookie to expire
        } else {
            console.log('No auth cookie found')
        }
    }

    //authHeader = `Bearer  ${this.getCookie('auth')}`

    removeAuthKey = () => {
        let domain = undefined // This works with localhost
        if (window.location.host.includes('demo.')) {
            domain = '.demo.remotivelabs.com'
        } else if (window.location.host.includes('cloud-dev.')) {
            domain = '.cloud-dev.remotivelabs.com'
        } else if (window.location.host.includes('cloud.')) {
            domain = '.cloud.remotivelabs.com'
        }

        this.removeCookie('auth', '/', domain)
    }

    /* URLs */

    createQueryParams = (isSignUpRequested: boolean, redirectUrl: string | null) => {
        let queryParams: string = `signUpRequested=${isSignUpRequested}`
        if (redirectUrl !== null) {
            window.localStorage.removeItem('redirectUrl')
            queryParams = queryParams.concat(`&redirectUrl=${encodeURIComponent(redirectUrl)}`)
        }
        return queryParams
    }

    setLoginUrl = (isSignUpRequested: boolean) => {
        const redirectUrl = window.localStorage.getItem('redirectUrl')
        const queryParams = this.createQueryParams(isSignUpRequested, redirectUrl)
        window.location.replace(`${this.getBackendURL()}/login?${queryParams}`)
    }

    getBrokerAppBaseUrl = () => {
        if (process.env.REACT_APP_BROKER_APP_BASE_URL) {
            return process.env.REACT_APP_BROKER_APP_BASE_URL
        }
        if (window.location.host.endsWith('cloud.remotivelabs.com')) {
            return 'https://broker-app.cloud.remotivelabs.com'
        }
        if (window.location.host.endsWith('cloud-dev.remotivelabs.com')) {
            return 'https://broker-app.cloud-dev.remotivelabs.com'
        }
        if (window.location.host.endsWith('demo.remotivelabs.com')) {
            return 'https://broker-app.demo.remotivelabs.com'
        }
        return 'https://broker-app.cloud-dev.remotivelabs.com'
    }

    getProjectHomeUrl = (projectUid: string) => {
        return `/p/${projectUid}/recordings`
    }

    getOrganisationHomeUrl = (billableUnit: UserBillableUnitInfo) => {
        return `/orgs/${billableUnit.organisation.uid}`
    }

    getOrganisationHomeUrlFromUid = (billableUnitId: string) => {
        return `/orgs/${billableUnitId}`
    }

    getBrokerPageUrl = (projectUid: string, brokerName: string) => {
        return `/p/${projectUid}/brokers/${brokerName}`
    }

    getBrokerAppUrl = (broker: BrokerContainer, isDemo?: boolean, currentProjectId?: string, isIframe?: boolean) => {
        return (
            `${this.getBrokerAppBaseUrl()}?` +
            `api_key=${broker?.keys.toString()}` +
            `&broker=${broker?.url}` +
            `${isDemo ? '&is_demo=true' : ''}` +
            `${isIframe ? '&isIframe=true' : ''}` +
            `${currentProjectId ? `&last_used_project=${currentProjectId}` : ''}`
        )
    }

    // Admin

    migrate = (): Promise<string> => {
        //remotiveApiClient.defaults.headers.common['Authorization'] = "Bearer " + authToken()
        return this.remotiveApiClient.get('/api/admin/migrate')
    }

    /* Billable units */

    listOrganisations = (): Promise<AxiosResponse<Array<UserBillableUnitInfo>>> => {
        return this.remotiveApiClient.get(`/api/bu`)
    }

    listLicenses = (bu: string, filter: string | undefined): Promise<AxiosResponse<Array<UserLicense>>> => {
        return this.remotiveApiClient.get(`/api/bu/${bu}/licenses?filter=${filter}`)
    }

    defaultUserBu = (): Promise<AxiosResponse<UserBillableUnitInfo>> => {
        return this.remotiveApiClient.get(`/api/bu/me`)
    }

    /* Users */

    getTokenTtl = (): Promise<AxiosResponse<TokenTtl>> => {
        return this.remotiveApiClient.get('/api/open/token/ttl')
    }

    whoami = (): Promise<AxiosResponse<AuthenticatedUser>> => {
        return this.remotiveApiClient.get('/api/whoami')
    }

    removeOngoingSignup = (state: string) => {
        return this.remotiveApiClient.delete(`/temporary-user-signup/${state}`)
    }

    listBuUsersForProject = (projectUid: string): Promise<AxiosResponse<Array<UserInfoWithRoles>>> => {
        return this.remotiveApiClient.get(`/api/project/${projectUid}/admin/users/bu`)
    }

    listProjectUsers = (projectUid: string): Promise<AxiosResponse<Array<UserInfoWithRoles>>> => {
        return this.remotiveApiClient.get(`/api/project/${projectUid}/admin/users`)
    }

    editProjectUser = (projectUid: string, user: UserInfoWithRoles): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.put(
            `/api/project/${projectUid}/admin/users/${user.user.uid}`,
            JSON.stringify(user),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    removeProjectUser = (projectUid: string, user: UserInfoWithRoles): Promise<any> => {
        return this.remotiveApiClient.delete(`/api/project/${projectUid}/admin/users/${user.user.uid}`)
    }

    listProjectServiceAccounts = (projectUid: string): Promise<AxiosResponse<Array<ProjectServiceAccountDTO>>> => {
        return this.remotiveApiClient.get(`/api/project/${projectUid}/admin/accounts`)
    }

    createProjectServiceAccount = (
        projectUid: string,
        createProjectServiceAccountDTO: CreateProjectServiceAccountDTO
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.post(
            `/api/project/${projectUid}/admin/accounts`,
            JSON.stringify(createProjectServiceAccountDTO),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    editProjectServiceAccount = (
        projectUid: string,
        editProjectServiceAccountDTO: EditProjectServiceAccountDTO,
        serviceAccountName: string
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.put(
            `/api/project/${projectUid}/admin/accounts/${serviceAccountName}`,
            JSON.stringify(editProjectServiceAccountDTO),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    removeProjectServiceAccount = (
        projectUid: string,
        projectServiceAccount: ProjectServiceAccount
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.delete(`/api/project/${projectUid}/admin/accounts/${projectServiceAccount.name}`)
    }

    listProjectServiceAccountAccessTokens = (
        projectUid: string,
        projectServiceAccount: ProjectServiceAccount
    ): Promise<AxiosResponse<Array<ServiceAccountTokenDTO>>> => {
        return this.remotiveApiClient.get(
            `/api/project/${projectUid}/admin/accounts/${projectServiceAccount.name}/keys`
        )
    }

    revokeServiceAccountAccessToken = (
        projectUid: string,
        projectServiceAccount: ProjectServiceAccount,
        serviceAccountKeyName: string
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${projectUid}/admin/accounts/${projectServiceAccount.name}/keys/${serviceAccountKeyName}`
        )
    }

    createServiceAccountAccessToken = (
        projectUid: string,
        projectServiceAccount: ProjectServiceAccount,
        createServiceAccountKeyDTO: CreateServiceAccountTokenDTO
    ): Promise<AxiosResponse<ServiceAccountKeyCreatedDTO>> => {
        return this.remotiveApiClient.post(
            `/api/project/${projectUid}/admin/accounts/${projectServiceAccount.name}/keys`,
            JSON.stringify(createServiceAccountKeyDTO),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    createPersonalAccessToken = (): Promise<AxiosResponse<ServiceAccountKeyCreatedDTO>> => {
        const body = undefined //JSON.stringify(createServiceAccountKeyDTO)
        return this.remotiveApiClient.post(`/api/me/keys`, body, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    listPersonalAccessTokens = (): Promise<AxiosResponse<Array<PersonalAccessTokenDTO>>> => {
        return this.remotiveApiClient.get(`/api/me/keys`)
    }

    revokePersonalAccessToken = (personalAccessToken: AccessToken): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.delete(`/api/me/keys/${personalAccessToken.name}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    listBuUsers = (bu: string): Promise<AxiosResponse<Array<UserInfoWithRoles>>> => {
        return this.remotiveApiClient.get(`/api/bu/${bu}/users`)
    }

    editBuUser = (bu: string, user: UserInfoWithRoles): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.put(`/api/bu/${bu}/users/${user.user.uid}`, JSON.stringify(user), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    deleteBuUser(user: UserInfoWithRoles, org: Organisation): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.delete(`/api/bu/${org.uid}/users/${user.user.uid}`)
    }

    createBuUser(bu: string, user: CreateUserInfoWithRoles): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.post(`/api/bu/${bu}/users`, JSON.stringify(user), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    postAgreementAnswers(request: AgreementsAnswersRequest): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.post(`/api/agreement`, JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    putFeatureEnabledState(request: FeatureEnabledStateRequest, billableUnitUid: string): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.put(`/api/bu/${billableUnitUid}/feature-state`, JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    /* Recording Sessions */

    listRecordingSessions = (project: string): Promise<AxiosResponse<Array<RecordingSession>>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording`)
    }

    listSampleRecordingSessions = (): Promise<AxiosResponse<Array<RecordingSession>>> => {
        return this.remotiveApiClient.get(`/api/samples/files/recording`)
    }

    getRecordingSession(project: string, sessionId: string): Promise<AxiosResponse<RecordingSession>> {
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/${sessionId}`)
    }

    copySampleRecordingSession(destinationProject: string, sessionId: string) {
        console.log(destinationProject)
        return this.remotiveApiClient.post(
            `/api/samples/files/recording/${sessionId}/copy`,
            { projectUid: destinationProject },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    copyRecordingSession(project: string, sessionId: string, destinationProject: string) {
        console.log(destinationProject)
        return this.remotiveApiClient.post(
            `/api/project/${project}/files/recording/${sessionId}/copy`,
            { projectUid: destinationProject },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    editRecordingSession(
        project: string,
        sessionId: string,
        displayName: string,
        description: string
    ): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.put(`/api/project/${project}/files/recording/${sessionId}`, {
            newDisplayName: displayName,
            newDescription: description,
        })
    }

    deleteRecordingSession = (project: string, sessionId: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(`/api/project/${project}/files/recording/${sessionId}`)
    }

    uploadRecordingSession(
        project: string,
        file: File,
        dbcFile: string | undefined,
        config: AxiosRequestConfig
    ): Promise<AxiosResponse<string>> {
        const type = dbcFile ? 'candump' : 'remotive-recording'
        const body = dbcFile ? { dbcFile: dbcFile } : undefined

        return this.remotiveApiClient
            .post(`/api/project/${project}/files/recording/${file.name}`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-recording-type': type,
                },
            })
            .then((res) => {
                return this.externalApiClient.put(res.data, file, config)
            })
    }

    applyRecordingSessionToBroker = (
        project: string,
        session: RecordingSession,
        broker: string,
        brokerConfigFolder: BrokerConfigurationFolder | undefined = undefined
    ): Promise<AxiosResponse<BrokerContainer>> => {
        console.log(`start recording ${project} ${session.displayName} on ${broker}`)
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/${session.sessionId}/upload`, {
            params: { brokerName: broker, brokerConfigName: brokerConfigFolder?.name },
        })
    }

    /* Recording Files */

    deleteRecordingFile = (
        project: string,
        sessionId: string,
        recordingFile: RecordingFile
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/recording-file/${recordingFile.fileName}`
        )
    }

    deleteProcessingRecordingFile = (
        project: string,
        recordingFile: ProcessingUploadFile
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/processing-file/${recordingFile.uploadId}`
        )
    }

    deleteProcessingSigdbFile = (
        project: string,
        recordingFile: ProcessingUploadFile
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/config/processing/${recordingFile.uploadId}`
        )
    }

    getRecordingFile(
        project: string,
        sessionId: string,
        recordingFileName: string
    ): Promise<AxiosResponse<RecordingFile>> {
        return this.remotiveApiClient.get(
            `/api/project/${project}/files/recording/${sessionId}/recording-file/${recordingFileName}`
        )
    }

    updateRecordingFileConfiguration(
        project: string,
        sessionId: string,
        recordingFileName: string,
        namespace: string,
        signalDatabaseName: string
    ): Promise<AxiosResponse<RecordingFile>> {
        return this.remotiveApiClient.patch(
            `/api/project/${project}/files/recording/${sessionId}/recording-file/${recordingFileName}/metadata`,
            JSON.stringify({ namespace: namespace, database: signalDatabaseName }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    applyRecordingFileToBroker = (
        project: string,
        sessionId: string,
        fileName: string,
        broker: string
    ): Promise<AxiosResponse<BrokerContainer>> => {
        console.log(`start recording ${project} ${fileName} on ${broker}`)
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/${sessionId}/${fileName}/upload`, {
            params: { brokerName: broker },
        })
    }

    listProcessingRecordingFiles(project: string): Promise<AxiosResponse<Array<ProcessingUploadFile>>> {
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/processing`)
    }

    listProcessingSigdbFiles(project: string): Promise<AxiosResponse<Array<ProcessingUploadFile>>> {
        return this.remotiveApiClient.get(`/api/project/${project}/files/config/processing`)
    }

    /* Media Files */

    getMediaFile(project: string, sessionId: string, mediaFile: MediaFile): Promise<AxiosResponse<MediaFile>> {
        return this.remotiveApiClient.get(
            `/api/project/${project}/files/recording/${sessionId}/media/${mediaFile.fileName}`
        )
    }

    getSourceRecordingFile(
        project: string,
        sessionId: string,
        sourceFile: SourceRecordingFile
    ): Promise<AxiosResponse<SourceRecordingFile>> {
        return this.remotiveApiClient.get(
            `/api/project/${project}/files/recording/${sessionId}/source-recording-file/${sourceFile.fileName}`
        )
    }

    deleteMediaFile = (project: string, sessionId: string, mediaFile: MediaFile): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/media/${mediaFile.fileName}`
        )
    }

    deleteSourceRecordingFile = (
        project: string,
        sessionId: string,
        sourceRecordingFile: SourceRecordingFile
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/source-recording-file/${sourceRecordingFile.fileName}`
        )
    }

    downloadBrokerConfiguration = (
        project: string,
        sessionId: string,
        brokerConfigurationFolder: BrokerConfigurationFolder
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.get(
            `/api/project/${project}/files/recording/${sessionId}/configuration/${brokerConfigurationFolder.name}`,
            {
                responseType: 'arraybuffer',
            }
        )
    }

    deleteBrokerConfigFolder = (
        project: string,
        sessionId: string,
        brokerConfigurationFolder: BrokerConfigurationFolder
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/configuration/${brokerConfigurationFolder.name}`
        )
    }

    async uploadBrokerConfigToRecordingSession(
        projectName: string,
        recordingId: string,
        configName: string,
        files: Array<FileWithPath>
    ) {
        //const f = new FormData()
        //const blob: Blob = file as Blob
        //f.append(file.name, blob)
        const uploadUrlResponse: AxiosResponse<any> = await this.remotiveApiClient.put(
            `/api/project/${projectName}/files/recording/${recordingId}/configuration`,
            JSON.stringify({
                name: configName,
                paths: files.map((f) => f.path),
            }),
            {
                headers: {
                    'Content-type': 'application/json',
                },
            }
        )

        console.log(uploadUrlResponse.data)
        const map = new Map(Object.entries(uploadUrlResponse.data))
        files.forEach((f) => {
            let path = f.path!
            if (!path!.startsWith('/')) {
                path = `/${path}`
            }
            const url = map.get(path)
            return this.externalApiClient.put(url as string, f, {})
        })
    }

    async uploadRecordingSessionRecordingFile(
        projectName: string,
        recordingId: string,
        file: File,
        config: AxiosRequestConfig
    ): Promise<AxiosResponse<string>> {
        return this.remotiveApiClient
            .post(`/api/project/${projectName}/files/recording/${recordingId}/recording-file/${file.name}`)
            .then((res) => {
                return this.externalApiClient.put(res.data, file, config)
            })
    }

    async uploadRecordingSessionMediaFile(
        projectName: string,
        recordingId: string,
        file: File,
        config: AxiosRequestConfig
    ): Promise<AxiosResponse<string>> {
        const uploadUrlResponse: AxiosResponse<any> = await this.remotiveApiClient.put(
            `/api/project/${projectName}/files/recording/${recordingId}/media/${file.name}`
        )

        const { url, contentType } = uploadUrlResponse.data
        config.headers = {
            'Content-type': contentType,
        }

        return this.externalApiClient.put(url, file, config)
    }

    /* Signal databases */

    listSignalDatabases = (project: string): Promise<AxiosResponse<Array<SignalDatabase>>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/files/config`)
    }

    deleteSignalDatabase = (project: string, fileName: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(`/api/project/${project}/files/config/${fileName}`)
    }

    downloadSignalDatabase = (project: string, fileName: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/files/config/${fileName}/download`)
    }

    async uploadSignalDatabase(project: string, file: File): Promise<AxiosResponse<string>> {
        const uploadUrlResponse: AxiosResponse<StorageUploadResponse> = await this.remotiveApiClient.put(
            `/api/project/${project}/files/config/${file.name}/uploadfile`
        )
        const url = uploadUrlResponse.data
        console.log(url)

        return this.externalApiClient.put(url.url, file, {
            //headers: Object.fromEntries(url.headers),
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
    }

    /* Projects */

    listProjects = (bu: string): Promise<AxiosResponse<UserBillableUnitInfo>> => {
        return this.remotiveApiClient.get(`/api/bu/${bu}/me`)
    }

    createProject = (
        request: CreateProjectRequest,
        ownerOrganisationUid: string
    ): Promise<AxiosResponse<UserBillableUnitInfo>> => {
        return this.remotiveApiClient.post(`/api/bu/${ownerOrganisationUid}/project`, JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    deleteProject = (project: Project): Promise<any> => {
        return this.remotiveApiClient.delete(`/api/project/${project.uid}`)
    }

    /* Billable units */

    createBillableUnit = (request: CreateBuRequest): Promise<AxiosResponse<BillableUnit>> => {
        return this.remotiveApiClient.post(`/api/bu`, JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    deleteBillableUnit = (billableUnit: BillableUnit) => {
        return this.remotiveApiClient.delete(`/api/bu/${billableUnit.uid}`)
    }

    /* Brokers */

    /**
     * Lists ALL brokers in the requested project, this will include all personal brokers as well
     */
    listAllBrokers = (project: string): Promise<AxiosResponse<Array<BrokerContainer>>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/brokers`)
    }

    getBroker = async (
        project: string,
        brokerName: string,
        user: AuthenticatedUser
    ): Promise<BrokerContainer | undefined> => {
        const broker = (await this.remotiveApiClient.get(
            `/api/project/${project}/brokers/${brokerName}`
        )) as AxiosResponse<BrokerContainer>
        return broker.data
    }

    getPersonalBroker = async (project: string, user: AuthenticatedUser): Promise<BrokerContainer | undefined> => {
        try {
            const broker = (await this.remotiveApiClient.get(
                `/api/project/${project}/brokers/personal`
            )) as AxiosResponse<BrokerContainer>
            return broker.data
        } catch (err: any) {
            if (err.response.status === 404) {
                return undefined
            }
            throw err
        }
    }

    createBroker = (
        project: string,
        brokerName: string,
        apiKey: string | undefined,
        tag: string | undefined,
        lifecycle: string | undefined = 'EPHEMERAL'
    ): Promise<AxiosResponse<BrokerContainer>> => {
        const RESOURCE_SIZE = 'S'
        return this.remotiveApiClient.post(
            `/api/project/${project}/brokers/${brokerName}`,
            {
                size: RESOURCE_SIZE,
                apiKey: apiKey,
                tag: tag?.trim().length === 0 ? undefined : tag?.trim(),
                type: lifecycle,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    createPersonalBroker = (
        project: string,
        apiKey: string | undefined,
        tag: string | undefined,
        lifecycle: string | undefined = 'EPHEMERAL'
    ): Promise<AxiosResponse<BrokerContainer>> => {
        const RESOURCE_SIZE = 'S'
        return this.remotiveApiClient.post(
            `/api/project/${project}/brokers/personal`,
            {
                size: RESOURCE_SIZE,
                apiKey: apiKey,
                tag: tag?.trim().length === 0 ? undefined : tag?.trim(),
                type: lifecycle,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    /**
     * List all non personal brokers in the requested project, INCLUDING your own personal broker if it exists
     */
    listNonPersonalBrokers = async (project: string, user: AuthenticatedUser): Promise<Array<BrokerContainer>> => {
        const brokers = (await this.remotiveApiClient.get(`/api/project/${project}/brokers`))
            .data as Array<BrokerContainer>
        const personalBrokerName = await this._getPersonalBrokerName(user)
        const nonPersonalBrokersIncludingOwnBroker = brokers
            .map((broker) => {
                if (broker.name.includes('personal-')) {
                    if (broker.name === personalBrokerName) {
                        return this._replacePersonalBrokerName(broker)
                    }
                    return undefined
                }
                return broker
            })
            .filter((broker) => broker !== undefined) as Array<BrokerContainer>
        return nonPersonalBrokersIncludingOwnBroker
    }

    _replacePersonalBrokerName = (broker: BrokerContainer) => {
        return { ...broker, shortName: 'My personal broker' } as BrokerContainer
    }

    _getPersonalBrokerName = async (user: AuthenticatedUser) => {
        const suggestedName = `personal-${user.uid.toLowerCase()}`
        return suggestedName.substring(0, Math.min(suggestedName.length, 23))
    }

    deleteBroker = async (
        project: string,
        brokerName: string,
        currentUser: AuthenticatedUser
    ): Promise<AxiosResponse<BrokerContainer>> => {
        // TODO - Lets discuss how to best make sure its a personal broker
        //        There is from now a boolean field personal = true|false that could be used
        //        but that is not available for older brokers
        if (brokerName === (await this._getPersonalBrokerName(currentUser))) {
            return this.deletePersonalBroker(project)
        } else {
            return this.remotiveApiClient.delete(`/api/project/${project}/brokers/${brokerName}`)
        }
    }

    deletePersonalBroker = (project: string): Promise<AxiosResponse<BrokerContainer>> => {
        return this.remotiveApiClient.delete(`/api/project/${project}/brokers/personal`)
    }

    getSignalsTimeseries = (
        project: string,
        sessionId: string,
        signalIds: Array<SignalIdentifier>
    ): Promise<AxiosResponse<SignalList>> => {
        return this.remotiveApiClient.post(`/api/project/${project}/analytics/recording/${sessionId}/signals`, {
            signals: signalIds,
        })
    }

    exportTimeseries = (
        project: string,
        sessionId: string,
        signalIds: Array<SignalIdentifier>
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.post(`/api/project/${project}/analytics/recording/${sessionId}/signals/export`, {
            signals: signalIds,
        })
    }

    getJitter = (
        project: string,
        sessionId: string,
        frame: string
    ): Promise<AxiosResponse<TwoDimensionalQueryResult>> => {
        return this.remotiveApiClient.get(
            `/api/project/${project}/analytics/recording/${sessionId}/signals/jitter?frame=${frame}`
        )
    }

    listSignalNames = (
        project: string,
        sessionId: string,
        optionalConfig?: AxiosRequestConfig
    ): Promise<AxiosResponse<SignalNameList>> => {
        return this.remotiveApiClient.get(
            `/api/project/${project}/analytics/recording/${sessionId}/signals`,
            optionalConfig
        )
    }

    listFrameEntries = (
        project: string,
        sessionId: string,
        optionalConfig?: AxiosRequestConfig
    ): Promise<AxiosResponse<Array<FrameEntry>>> => {
        return this.remotiveApiClient.get(
            `/api/project/${project}/analytics/recording/${sessionId}/frames`,
            optionalConfig
        )
    }

    getVisualizeProcessingTracker = (
        projectId: string,
        sessionId: string,
        optionalConfig?: AxiosRequestConfig
    ): Promise<AxiosResponse<VisualizeProcessingTracker>> => {
        return this.remotiveApiClient.get(
            `/api/project/${projectId}/analytics/recording/${sessionId}/frames/processing`,
            optionalConfig
        )
    }

    listFrameNames = (project: string, sessionId: string): Promise<AxiosResponse<SignalNameList>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/analytics/recording/${sessionId}/signals/frames`)
    }

    requestCrunchRecording = (currentProject: Project, recordingSessionId: string): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.post(
            `/api/project/${currentProject?.uid}/analytics/recording/${recordingSessionId}`
        )
    }

    deleteAnalytics = (currentProject: Project, recordingSessionId: string): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${currentProject?.uid}/analytics/recording/${recordingSessionId}`
        )
    }

    uploadStorageFile = async (currentProject: Project, parentPath: string, file: File) => {
        const uploadUrlResponse: AxiosResponse<any> = await this.remotiveApiClient.post(
            `/api/project/${currentProject.uid}/files/storage/${parentPath}/${file.name}`
        )
        const url = uploadUrlResponse.data
        return this.externalApiClient.put(url, file, {
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
    }

    getStorageFileUrl = async (currentProject: Project, path: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.get(`/api/project/${currentProject.uid}/files/storage${path}?download=true`)
    }

    downloadPreviewContentsFromSignedUrl = async (
        url: string,
        maxBytes: number = 1024 * 10
    ): Promise<AxiosResponse<string>> => {
        return this.externalApiClient.get(url, {
            headers: {
                Range: `bytes=0-${maxBytes}`,
            },
        })
    }

    deleteStorageFile = async (currentProject: Project, path: string) => {
        return this.remotiveApiClient.delete(`/api/project/${currentProject.uid}/files/storage${path}`)
    }

    listStorageFilesInPath = async (
        currentProject: Project,
        path?: string
    ): Promise<AxiosResponse<FileObjectsContainer>> => {
        return this.remotiveApiClient.get(`/api/project/${currentProject.uid}/files/storage${path || '/'}`)
    }

    createRecordingAnnotation = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        recordingAnnotationRequest: CreateRecordingAnnotationRequest
    ): Promise<AxiosResponse<RecordingAnnotation>> => {
        return this.remotiveApiClient.post(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations`,
            recordingAnnotationRequest
        )
    }

    getRecordingAnnotation = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        annotationId: string
    ): Promise<AxiosResponse<RecordingAnnotation>> => {
        return this.remotiveApiClient.get(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations/${annotationId}`
        )
    }

    listRecordingAnnotations = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession
    ): Promise<AxiosResponse<Array<RecordingAnnotation>>> => {
        return this.remotiveApiClient.get(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations`
        )
    }

    deleteRecordingAnnotation = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        annotation: RecordingAnnotation
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations/${annotation.id}`
        )
    }

    editRecordingAnnotation = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        annotation: RecordingAnnotation,
        editRecordingAnnotationRequest: EditRecordingAnnotationRequest
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.put(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations/${annotation.id}`,
            editRecordingAnnotationRequest
        )
    }

    createRecordingAnnotationComment = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        annotation: RecordingAnnotation,
        recordingAnnotationCommentRequest: RecordingAnnotationCommentRequest
    ): Promise<AxiosResponse<RecordingAnnotationComment>> => {
        return this.remotiveApiClient.post(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations/${annotation.id}/comments`,
            recordingAnnotationCommentRequest
        )
    }

    editRecordingAnnotationComment = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        annotation: RecordingAnnotation,
        oldComment: RecordingAnnotationComment,
        recordingAnnotationCommentRequest: RecordingAnnotationCommentRequest
    ): Promise<AxiosResponse<RecordingAnnotationComment>> => {
        return this.remotiveApiClient.put(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations/${annotation.id}/comments/${oldComment.id}`,
            recordingAnnotationCommentRequest
        )
    }

    deleteRecordingAnnotationComment = async (
        currentProject: Project,
        currentRecordingSession: RecordingSession,
        annotation: RecordingAnnotation,
        comment: RecordingAnnotationComment
    ): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${currentProject.uid}/analytics/recording/${currentRecordingSession.sessionId}/annotations/${annotation.id}/comments/${comment.id}`
        )
    }
}

// Sneaky little singleton
export default new MonolithApi()
